<template>

<div class="position-relative">
  <div class="">
    <div class="bg-light">
          <div class="container py-5">
            <div class="row h-100 align-items-center py-5">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 mt-5">
                <h3 class="display-4 font-weight-bold text-white mt-5">Promotions</h3>
                <p class="lead text-white mb-5">
                Find out more about the latest promotions from us
                </p>
              </div>
            </div>
          </div>
        </div>
      <div class="d-flex justify-content-around" v-if="loading">
        <loader-component></loader-component>
      </div>
    <div class="container mb-5" v-if="!loading">
      <img :src="data.file_endpoint" class="img-fluid b-radius mt-5" alt="" />
      <h1 class="h1 mt-3">{{ data.title }}</h1>
      <p>
        {{ data.description }}
      </p>
      <div class="d-flex justify-content-around">
        <p class="badge badge-success p-2">
          Valid Until {{ moment(data.expired_at).format("DD-MM-YYYY") }}
        </p>
        <p class="badge badge-primary p-2">
          Max Amount {{ data.discount }} IDR
        </p>
      </div>
      <button
        class="btn btn-primary px-4"
        v-if="token && role == 'customers'"
        @click.prevent="toSubmitPackages(data.code)"
      >
        Use Promotion
      </button>
      <button class="btn btn-primary px-4" v-else @click.prevent="toLogin">
        Login for use this promotion
      </button>
    </div>
  </div>
</div>
  
</template>
<script>
import LoaderComponent from "../../components/Loader.vue";
export default {
  props: ["slug"],
  name: "Detailpromo",
  components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
    };
  },
  mounted() {
    console.log(this.slug);
    if (this.slug) {
      this.getDetailPromotion(this.slug);
    }
  },
  methods: {
    getDetailPromotion(slug) {
      this.loading = true;
      const endpoint = "promotion/" + slug;
      this.$api.get(endpoint, (status, data, message) => {
        console.log(status);
        if (status == 200) {
          this.loading = false;
          this.data = data.promotion;
          console.log(data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    toLogin() {
      this.$router.push("/login");
    },

    toSubmitPackages(promotion_code) {
      this.$router.push("/customers/submit-packages/" + promotion_code);
    },
  },
};
</script>
